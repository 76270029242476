import { render, staticRenderFns } from "./Overlay.vue?vue&type=template&id=07f26680&scoped=true&"
import script from "./Overlay.vue?vue&type=script&lang=ts&"
export * from "./Overlay.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("@/styles/color.module.css?vue&type=style&index=0&id=07f26680&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)
var style1 = require("@/styles/font-weight.module.css?vue&type=style&index=1&module=%24fw&lang=css&")
if (style1.__inject__) style1.__inject__(context)
this["$fw"] = (style1.locals || style1)
var style2 = require("@/styles/padding.module.css?vue&type=style&index=2&module=%24p&lang=css&")
if (style2.__inject__) style2.__inject__(context)
this["$p"] = (style2.locals || style2)
var style3 = require("@/styles/border.module.css?vue&type=style&index=3&module=%24b&lang=css&")
if (style3.__inject__) style3.__inject__(context)
this["$b"] = (style3.locals || style3)
var style4 = require("@/styles/font.module.css?vue&type=style&index=4&id=07f26680&scoped=true&lang=css&")
if (style4.__inject__) style4.__inject__(context)
var style5 = require("@/styles/margin.module.css?vue&type=style&index=5&id=07f26680&scoped=true&lang=css&")
if (style5.__inject__) style5.__inject__(context)
var style6 = require("./Overlay.vue?vue&type=style&index=6&id=07f26680&scoped=true&lang=css&")
if (style6.__inject__) style6.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "07f26680",
  null
  ,true
)

export default component.exports