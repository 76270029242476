import { render, staticRenderFns } from "./App.vue?vue&type=template&id=5ac35b04&scoped=true&"
import script from "./App.vue?vue&type=script&lang=ts&"
export * from "./App.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("@/styles/bg-color.module.css?vue&type=style&index=0&id=5ac35b04&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)
var style1 = require("@/styles/color.module.css?vue&type=style&index=1&id=5ac35b04&scoped=true&lang=css&")
if (style1.__inject__) style1.__inject__(context)
var style2 = require("./App.vue?vue&type=style&index=2&id=5ac35b04&scoped=true&lang=css&")
if (style2.__inject__) style2.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5ac35b04",
  null
  ,true
)

export default component.exports