exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".padding-module_p1_3iICB{padding:.25rem}.padding-module_p2_3srbg{padding:.5rem}.padding-module_p3_3FD2Q{padding:.75rem}.padding-module_p4_1TvKa{padding:1rem}.padding-module_px1_2xj5Z{padding-left:.25rem;padding-right:.25rem}.padding-module_px2_li44o{padding-left:.5rem;padding-right:.5rem}.padding-module_px3_2aiSj{padding-left:.75rem;padding-right:.75rem}.padding-module_px4_3E1o7{padding-left:1rem;padding-right:1rem}.padding-module_py1_4Pi_p{padding-top:.25rem;padding-bottom:.25rem}.padding-module_py2_1JxKB{padding-top:.5rem;padding-bottom:.5rem}.padding-module_py3_k31yG{padding-top:.75rem;padding-bottom:.75rem}.padding-module_py4_12SxW{padding-top:1rem;padding-bottom:1rem}", ""]);
// Exports
exports.locals = {
	"p1": "padding-module_p1_3iICB",
	"p2": "padding-module_p2_3srbg",
	"p3": "padding-module_p3_3FD2Q",
	"p4": "padding-module_p4_1TvKa",
	"px1": "padding-module_px1_2xj5Z",
	"px2": "padding-module_px2_li44o",
	"px3": "padding-module_px3_2aiSj",
	"px4": "padding-module_px4_3E1o7",
	"py1": "padding-module_py1_4Pi_p",
	"py2": "padding-module_py2_1JxKB",
	"py3": "padding-module_py3_k31yG",
	"py4": "padding-module_py4_12SxW"
};